import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    this.initArticleReferences();
  }

  initArticleReferences() {
    $(this.element).find("span.article-reference").each(function(i, e) {
      var locale = $("html").attr('lang');
      e = $(e);
      if(locale == 'de'){
        e.replaceWith("<a href=\"/artikel/"+ e.data("term") +"\">"+ e.text() +"</a>")
      } else {
        e.replaceWith("<a href=\"/"+ locale  + "/artikel/"+ e.data("term") +"\">"+ e.text() +"</a>")
      }
    });
  }
}
